import Vue from "vue";
import Router from "vue-router";
import queryString from "query-string";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");
const UserPage = () => import("@/views/UserPage");
const UserList = () => import("@/views/UserList");
const ClientList = () => import("@/views/ClientList");
const BusinessPartnerRegistration = () => import("@/views/BusinessPartnerRegistration");
const ClientSetting = () => import("@/views/ClientSetting");
const ClientUserSetting = () => import("@/views/ClientUserSetting");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");
const ClientUserRegister = () => import("@/views/pages/ClientUserRegister");
const PrintPage = () => import("@/views/pages/PrintPage");

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),

  parseQuery: (query) => {
    return queryString.parse(query, {
      arrayFormat: "bracket",
    });
  },
  stringifyQuery: (params) => {
    if (0 == Object.keys(params).length) {
      return "";
    } else {
      return (
        "?" +
        queryString.stringify(params, {
          arrayFormat: "bracket",
        })
      );
    }
  },
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/pages/login",
      name: "Home",
      component: TheContainer,
      // beforeEnter: (to, from, next) => {
      //   next('/pages/login');
      // },
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "user-page",
          name: "UserPage",
          component: UserPage,
        },
        {
          path: "user-list",
          name: "UserList",
          component: UserList,
          // children: [
          //   {
          //     path: "user-page",
          //     name: "UserPage",
          //     component: UserPage,
          //   },
          // ],
        },
        {
          path: "client-list",
          name: "取引先一覧",
          component: ClientList,
        },
        {
          path: "client-setting/:clientId",
          name: "ClientSetting",
          component: ClientSetting,
        },
        {
          path: "client-user-setting/:uid",
          name: "ClientUserSetting",
          component: ClientUserSetting,
        },
        {
          path: "business-partner-registration",
          name: "取引先登録",
          component: BusinessPartnerRegistration,
        },
      ],
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "register",
          name: "Register",
          component: Register,
        },
        {
          path: "client-user-register",
          name: "ClientUserRegister",
          component: ClientUserRegister,
        },
        {
          path: "print-page",
          name: "PrintPage",
          component: PrintPage,
        },
      ],
    },
  ];
}

export default router;
