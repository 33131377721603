import Vue from "vue";
import Vuex from "vuex";
import router from "../router/index";
import firebase from "firebase";
import test from "./modules/firebase";

Vue.use(Vuex);

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: true,
  showUserRegistrationModal: false,
  showUpdateUserInformationModal: false,
  showRecordModal: false,
  role: "",
  inviteCode: "",
  clientInviteCode: null,
  authUser: null,
  userData: null,
  clientUserData: null,
  clientSettings: null,
  measuredValueOptions: {
    weight: [],
    weightDate: [],
    bodyFatPercentage: [],
    bodyFatPercentageDate: [],
    bodyFatWeight: [],
    bodyFatWeightDate: [],
    musclePercentage: [],
    musclePercentageDate: [],
    bodyAge: [],
    bodyAgeDate: [],
    basalMetabolism: [],
    basalMetabolismDate: [],
    visceralFatLevel: [],
    visceralFatLevelDate: [],
  },
  // measuredValueOptionsMin: {
  //   minWeight: null,
  //   minBodyFatPercentage: null,
  //   minBodyFatWeight: null,
  //   minBodyAge: null,
  //   minBasalMetabolism: null,
  //   minVisceralFatLevel: null,
  // },
  subMeasuredValues: [],
};

const getters = {
  getRole: (state) => {
    return state.clientUserData.clientUserData.role;
  },
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  setAuthUser(state, authUser) {
    state.authUser = authUser;
  },
  openUserRegistrationModal(state) {
    state.showUserRegistrationModal = true;
  },
  closeUserRegistrationModal(state) {
    state.showUserRegistrationModal = false;
  },
  openUpdateUserInformationModal(state) {
    state.showUpdateUserInformationModal = true;
  },
  closeUpdateUserInformationModal(state) {
    state.showUpdateUserInformationModal = false;
  },
  openRecordModal(state) {
    state.showRecordModal = true;
  },
  closeRecordModal(state) {
    state.showRecordModal = false;
  },
  getUserData(state, id) {
    state.userData = id;
  },
  getClientInviteCode(state, inviteCode) {
    state.clientInviteCode = inviteCode;
  },
  updateUserDataImage(state, userImage) {
    state.userData.userImage = userImage;
  },
  setClientUserData(state, clientUserData) {
    state.clientUserData = clientUserData;
  },
  setClientSettings(state, clientSettings) {
    state.clientSettings = clientSettings;
  },
  setInviteCode(state, inviteCode) {
    state.inviteCode = inviteCode;
  },
  setMeasuredValueOptions(state, options) {
    state.measuredValueOptions = options;
  },
  setMeasuredValueOptionsMin(state, minValue) {
    state.measuredValueOptionsMin = minValue;
  },
  setSubMeasuredValue(state, allSubMeasuredValues) {
    state.subMeasuredValues = allSubMeasuredValues;
  },
  pushMeasuredValue(state, measuredValue) {
    if (measuredValue.weight !== null) {
      state.measuredValueOptions.weight.push(measuredValue.weight);
      state.measuredValueOptions.weightDate.push(measuredValue.date);
    }
    if (measuredValue.bodyFatPercentage !== null) {
      state.measuredValueOptions.bodyFatPercentage.push(measuredValue.bodyFatPercentage);
      state.measuredValueOptions.bodyFatPercentageDate.push(measuredValue.date);
    }
    if (measuredValue.bodyFatWeight !== null) {
      state.measuredValueOptions.bodyFatWeight.push(measuredValue.bodyFatWeight);
      state.measuredValueOptions.bodyFatWeightDate.push(measuredValue.date);
    }
    if (measuredValue.musclePercentage !== null) {
      state.measuredValueOptions.musclePercentage.push(measuredValue.musclePercentage);
      state.measuredValueOptions.musclePercentageDate.push(measuredValue.date);
    }
    if (measuredValue.bodyAge !== null) {
      state.measuredValueOptions.bodyAge.push(measuredValue.bodyAge);
      state.measuredValueOptions.bodyAgeDate.push(measuredValue.date);
    }
    if (measuredValue.basalMetabolism !== null) {
      state.measuredValueOptions.basalMetabolism.push(measuredValue.basalMetabolism);
      state.measuredValueOptions.basalMetabolismDate.push(measuredValue.date);
    }
    if (measuredValue.visceralFatLevel !== null) {
      state.measuredValueOptions.visceralFatLevel.push(measuredValue.visceralFatLevel);
      state.measuredValueOptions.visceralFatLevelDate.push(measuredValue.date);
    }
    // state.measuredValueOptionsMin.minWeight = Math.min(
    //   ...state.measuredValueOptions.weight
    // );
    // state.measuredValueOptionsMin.minBodyFatPercentage = Math.min(
    //   ...state.measuredValueOptions.bodyFatPercentage
    // );
    // state.measuredValueOptionsMin.minBodyFatWeight = Math.min(
    //   ...state.measuredValueOptions.bodyFatWeight
    // );
    // state.measuredValueOptionsMin.minBodyAge = Math.min(
    //   ...state.measuredValueOptions.bodyAge
    // );
    // state.measuredValueOptionsMin.minBasalMetabolism = Math.min(
    //   ...state.measuredValueOptions.basalMetabolism
    // );
    // state.measuredValueOptionsMin.minVisceralFatLevel = Math.min(
    //   ...state.measuredValueOptions.visceralFatLevel
    // );
  },
};
// 9y4DldHzuP
const actions = {
  async signUp({ commit }, authData) {
    await firebase
      .auth()
      .createUserWithEmailAndPassword(authData.email, authData.password)
      .then(async (userCredential) => {
        // Signed in
        var authUser = userCredential.user;
        commit("setAuthUser", authUser);
        if (authUser.displayName == null) {
          await firebase.auth().currentUser.updateProfile({
            displayName: authUser.email,
          });
          localStorage.setItem("authUserName", authUser.email);
        } else {
          localStorage.setItem("authUserName", authUser.displayName);
        }
        if (authUser.photoURL == null) {
          await firebase.auth().currentUser.updateProfile({
            photoURL:
              "https://firebasestorage.googleapis.com/v0/b/eatas-app-4cfb1.appspot.com/o/icon%2Fdefault-user-icon.jpeg?alt=media&token=3925b928-b03c-419f-97aa-82f8680297d4",
          });
          localStorage.setItem(
            "photoURL",
            "https://firebasestorage.googleapis.com/v0/b/eatas-app-4cfb1.appspot.com/o/icon%2Fdefault-user-icon.jpeg?alt=media&token=3925b928-b03c-419f-97aa-82f8680297d4"
          );
        } else {
          localStorage.setItem("photoURL", authUser.photoURL);
        }
        router.push("/pages/client-user-register");
      })
      .catch((error) => {
        alert("無効なメールアドレスです。");
        var errorCode = error.code;
        var errorMessage = error.message;
        // ..
      });
  },

  async googleLogin({ commit }) {
    var provider = new firebase.auth.GoogleAuthProvider();
    // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    await firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return firebase
          .auth()
          .signInWithPopup(provider)
          .then((result) => {
            /** @type {firebase.auth.OAuthCredential} */
            var credential = result.credential;

            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = credential.accessToken;
            // The signed-in user info.
            var authUser = result.user;
            commit("setAuthUser", authUser);
            localStorage.setItem("authUserName", authUser.displayName);
            localStorage.setItem("photoURL", authUser.photoURL);
            // ...
            router.push("/dashboard");
          })
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
          });
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
      });
  },

  async login({ commit }, authData) {
    await firebase
      .auth()
      .signInWithEmailAndPassword(authData.email, authData.password)
      .then(async (userCredential) => {
        // Signed in
        var authUser = userCredential.user;
        // console.log(authUser);
        commit("setAuthUser", authUser);
        if (authUser.displayName == null) {
          localStorage.setItem("authUserName", authUser.email);
          await firebase.auth().currentUser.updateProfile({
            displayName: authUser.email,
          });
        } else {
          localStorage.setItem("authUserName", authUser.displayName);
        }
        if (authUser.photoURL == null) {
          localStorage.setItem(
            "photoURL",
            "https://firebasestorage.googleapis.com/v0/b/eatas-app-4cfb1.appspot.com/o/icon%2Fdefault-user-icon.jpeg?alt=media&token=3925b928-b03c-419f-97aa-82f8680297d4"
          );
          await firebase.auth().currentUser.updateProfile({
            photoURL:
              "https://firebasestorage.googleapis.com/v0/b/eatas-app-4cfb1.appspot.com/o/icon%2Fdefault-user-icon.jpeg?alt=media&token=3925b928-b03c-419f-97aa-82f8680297d4",
          });
        } else {
          localStorage.setItem("photoURL", authUser.photoURL);
        }
        router.push("/dashboard");
      })
      .catch((error) => {
        // console.log("失敗");
        const errorCode = error.code;
        const errorMessage = error.message;
        alert("メールアドレスまたはパスワードが間違っています。");
      });
  },
  async logout({ commit }) {
    // commit("updateIdToken", null);
    // localStorage.removeItem("idToken");
    // localStorage.removeItem("expiryTimeMs");
    // localStorage.removeItem("refreshToken");
    // console.log("logout");
    await firebase
      .auth()
      .signOut()
      .then(() => {
        router.replace("pages/login");
        localStorage.removeItem("authUserName");
        localStorage.removeItem("photoURL");
      })
      .catch((error) => {
        // console.log(error);
      });
  },

  async setClientUserData({ commit }, uid) {
    // let clientUserData;
    await firebase
      .firestore()
      .collection("clientUsers")
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let clientUserData = doc.data();
          commit("setClientUserData", clientUserData);
          // console.log("setClientUserData");
        } else {
          // console.log("No such clientUserData");
        }
      })
      .catch((error) => {
        // console.log("Error getting document:", error);
      });
  },

  async setClientSettings({ commit }, clientId) {
    // let clientUserData;
    await firebase
      .firestore()
      .collection("clients")
      .doc(`${clientId}`)
      .collection("clientSettings")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // clientSettings.push(doc.data());
          commit("setClientSettings", doc.data());
        });
      })
      .catch((error) => {
        // console.log("Error getting document:", error);
      });
  },

  /////////////// Ref作成 //////////////////

  async createChatDocRef() {
    return await firebase.firestore().collection("chat").doc(state.userData.id).collection("nutritionistChat").doc();
  },
  async createImageRecordingDocRef() {
    return await firebase.firestore().collection("images").doc(state.userData.id).collection("imageRecording").doc();
  },
  async createUserDocRef() {
    return await firebase.firestore().collection("users").doc();
  },
  async uploadImage({ commit }, { uri, path }) {
    // uriをblobに変換
    const localUri = await fetch(uri);
    const blob = await localUri.blob();
    const ref = firebase.storage().ref().child(path);

    let downloadUrl = "";
    try {
      await ref.put(blob);
      downloadUrl = await ref.getDownloadURL();
    } catch (err) {
      // console.log(err);
    }
    return downloadUrl;
  },
  async createMeasuredValue({ commit }) {
    // console.log(state.userData.id);
    await firebase
      .firestore()
      .collection("users")
      .doc(state.userData.id)
      .collection("measuredValue")
      .orderBy("createdAt", "asc")
      .get()
      .then((querySnapshot) => {
        let options = {
          weight: [],
          weightDate: [],
          bodyFatPercentage: [],
          bodyFatPercentageDate: [],
          bodyFatWeight: [],
          bodyFatWeightDate: [],
          musclePercentage: [],
          musclePercentageDate: [],
          bodyAge: [],
          bodyAgeDate: [],
          basalMetabolism: [],
          basalMetabolismDate: [],
          visceralFatLevel: [],
          visceralFatLevelDate: [],
        };
        let minValue = {
          minWeight: null,
          minBodyFatPercentage: null,
          minBodyFatWeight: null,
          minBodyAge: null,
          minBasalMetabolism: null,
          minVisceralFatLevel: null,
        };
        querySnapshot.forEach((doc) => {
          if (doc.data().measuredValue.weight !== null) {
            options.weight.push(doc.data().measuredValue.weight);
            options.weightDate.push(doc.data().measuredValue.date);
          }
          if (doc.data().measuredValue.bodyFatPercentage !== null) {
            options.bodyFatPercentage.push(doc.data().measuredValue.bodyFatPercentage);
            options.bodyFatPercentageDate.push(doc.data().measuredValue.date);
          }
          if (doc.data().measuredValue.bodyFatWeight !== null) {
            options.bodyFatWeight.push(doc.data().measuredValue.bodyFatWeight);
            options.bodyFatWeightDate.push(doc.data().measuredValue.date);
          }
          if (doc.data().measuredValue.musclePercentage !== null) {
            options.musclePercentage.push(doc.data().measuredValue.musclePercentage);
            options.musclePercentageDate.push(doc.data().measuredValue.date);
          }
          if (doc.data().measuredValue.bodyAge !== null) {
            options.bodyAge.push(doc.data().measuredValue.bodyAge);
            options.bodyAgeDate.push(doc.data().measuredValue.date);
          }
          if (doc.data().measuredValue.basalMetabolism !== null) {
            options.basalMetabolism.push(doc.data().measuredValue.basalMetabolism);
            options.basalMetabolismDate.push(doc.data().measuredValue.date);
          }
          if (doc.data().measuredValue.visceralFatLevel !== null) {
            options.visceralFatLevel.push(doc.data().measuredValue.visceralFatLevel);
            options.visceralFatLevelDate.push(doc.data().measuredValue.date);
          }
        });
        commit("setMeasuredValueOptions", options);
      });
    firebase
      .firestore()
      .collection("users")
      .doc(state.userData.id)
      .collection("subMeasuredValue")
      .orderBy("createdAt", "desc")
      // .limit(3)
      .get()
      .then((querySnapshot) => {
        let allSubMeasuredValues = [];
        querySnapshot.forEach((doc) => {
          allSubMeasuredValues.unshift({
            date: doc.data().date,
            upperArm: doc.data().upperArm,
            west: doc.data().west,
            hip: doc.data().hip,
            femur: doc.data().femur,
            lowerLeg: doc.data().lowerLeg,
          });
        });
        commit("setSubMeasuredValue", allSubMeasuredValues);
      });
  },
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  modules: {
    test,
  },
});
