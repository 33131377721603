const state = {
  authUser: null,
  clientUserData: {},
};

const getters = {
  authUser: (state) => state.authUser,
  clientUserData: (state) => state.clientUserData,
};

const mutations = {
  setAuthUser: (state, authUser) => {
    state.authUser = authUser;
  },
  setClientUserData: (state, clientUserData) => {
    state.clientUserData = clientUserData;
  },
};

const actions = {
  setAuthUser({ commit }, authUser) {
    commit("setAuthUser", authUser);
  },
  setClientUserData({ commit }, clientUserData) {
    commit("setClientUserData", clientUserData);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
